


























import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class ImportBatch extends Vue {
  public importBatch: any = [];

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private async getImport() {
    this.importBatch = await adminService.getImport(
      this.organisation.id,
      this.$route.params.id
    );

  }

  public async viewInvoice(id: string) {
        this.$router.push(`/invoices/${id}/`);
    
  }

  public async mounted() {
    await this.getImport();
  }
}
